<template >
  <v-container
    :style="codeSent ? 'position: absolute; top: 20%; width: 100%;' : 'position: absolute; bottom: 5%; width: 100%;'">

    <div v-if="codeSent === false" style="display: flex; justify-content: center;">
      <div>
        <div>
          <span style="color: white; font-size: 30px;">ברוכים הבאים!</span>
        </div>
        <div>
          <span style="color: white; font-size: 15px;">אנא הכניסו את מספר הפלאפון שלכם שאתו</span>
        </div>
        <div>
          <span style="color: white; font-size: 15px;">נרשמתם למערכת</span>
        </div>
        <v-form @submit.prevent="submitHandler" ref="form">
          <div style="text-align: center; margin-top: 20px; width: 100%;">
            <v-text-field class="custom-text-field" type="number" hide-spin-buttons hide-details v-model="account_id"
              label="הקלד כאן" solo text></v-text-field>
          </div>
        </v-form>
        <div style="margin-top: 20px;">
          <span style="color: white; font-size: 15px;">או הכנסו באמצעות שם משתמש וסיסמה</span>
        </div>
        <v-form @submit.prevent="submitHandler" ref="form2">
          <div style="text-align: center; margin-top: 10px; width: 100%;">
            <v-text-field class="custom-text-field" hide-details v-model="username" label="שם משתמש" solo
              text></v-text-field>
          </div>
          <div style="text-align: center; margin-top: 10px; width: 100%;">
            <v-text-field class="custom-text-field" type="password" hide-details v-model="password" label="סיסמה" solo
              text></v-text-field>
          </div>
        </v-form>
        <div style="text-align: center; width: 100%; margin-top: 20px;">
          <v-btn @click="sendAuth" class="gradiant-bt" dark style="width: 100%; border-radius: 20px; padding: 20px 16px;"
            text> <span style="font-weight: bold; font-size: large;">המשך</span>
          </v-btn>
        </div>
      </div>
    </div>

    <div v-else style="display: flex; justify-content: center;">
      <div>
        <div style="text-align: center;">
          <span style="color: white; font-size: 30px;">הכנס קוד אימות</span>
        </div>
        <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;">
          <img src="../../assets/icon_sms.svg"
            style="width: 30%; background-color: aliceblue; padding-left: 25px; border-radius: 30%; padding-right: 15px; padding-top: 15px; padding-bottom: 15px;">
        </div>
        <div>
          <span style="color: white; font-size: 15px;">ברגעים אלו ממש נשלח אליך sms</span>
        </div>
        <div>
          <span style="color: white; font-size: 15px;">אנא הכנס את קוד האימות שנשלח אליך</span>
        </div>
        <v-form @submit.prevent="submitHandler" ref="form">
          <div style="text-align: center; margin-top: 40px; width: 100%;">
            <v-text-field v-model="smsCode" ref="otpField" label="הכנס קוד אימות" class="custom-text-field" outlined dense
              type="number" hide-spin-buttons hide-details solo flat :rules="[v => !!v || 'שדה חובה']"
              @keydown.enter="handleEnterKeyOtp"></v-text-field>
          </div>
        </v-form>
        <div style="text-align: center; margin-top: 10px;">
          <span @click="resend" style="color: white; cursor: pointer; font-size: 15px;">לא קיבלתי, נא לשלוח שוב</span>
        </div>
        <div style="text-align: center; width: 100%; margin-top: 20px;">
          <v-btn @click="smsVerify" dark rounded class="gradiant-bt"
            style="width: 100%; border-radius: 20px; padding: 20px 16px;">
            <span style="font-weight: bold; font-size: large;">המשך</span>
          </v-btn>
        </div>
      </div>
    </div>

    <v-row v-if="progressShow" style="margin-top: 10px;">
      <v-col class="d-flex justify-center">
        <v-progress-circular indeterminate color="white"></v-progress-circular>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {

  components: {
    SnackBar
  },

  data: () => ({
    isResponsive: false,
    account_id: "",
    verifyCode: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    codeSent: false,
    smsCode: "",
    username: "",
    password: "",
  }),
  methods: {

    async loginByPhone() {
      if (this.$refs.form.validate()) {

        this.progressShow = true
        let MyJSON = JSON.stringify({ account_id: this.account_id });
        let api = process.env.VUE_APP_BASE_URL + "/auth/login"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          const jsonObject = await response.json();
          if (response.status >= 400) {
            this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
          } else if (response.status === 200) {
            this.showSnackBar("הזיהוי עבר בהצלחה, נשלח סמס לאימות", "green");
            localStorage.setItem("accountId", this.account_id)
            this.codeSent = true;
            this.$emit("removeRobot");
          }
        } catch (error) {
          this.showSnackBar("Error Login: " + error, "red");
        }

      }
    },
    async loginByUserName() {

      this.progressShow = true
      let MyJSON = JSON.stringify({ username: this.username, password: this.password });
      let api = process.env.VUE_APP_BASE_URL + "/auth/login2"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
        this.progressShow = false;
        const jsonObject = await response.json();
        if (response.status >= 400) {
          this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
        } else if (response.status === 200) {
          this.showSnackBar("התחברת בהצלחה!", "green");
          localStorage.setItem('token', jsonObject.token);
          const role = jsonObject.role;
          if (role === "user") {
            this.$router.replace({ name: "callHistory" })
          } else if (role === "admin") {
            this.$router.replace({ name: role })
          }
        }
      } catch (error) {
        this.showSnackBar("Error Login: " + error, "red");
      }

    },
    async smsVerify() {
      if (this.$refs.form.validate()) {

        this.progressShow = true
        const accountId = localStorage.getItem("accountId");
        let MyJSON = JSON.stringify({ account_id: accountId, smsCode: this.smsCode });
        let api = process.env.VUE_APP_BASE_URL + "/auth/verify_code"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          const jsonObject = await response.json();
          if (response.status >= 400) {
            this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
          } else if (response.status === 200) {
            this.showSnackBar("התחברת בהצלחה!", "green");
            localStorage.setItem('token', jsonObject.token);
            this.$router.replace({ name: "callHistory" })
          }
        } catch (error) {
          this.showSnackBar("Error Login: " + error, "red");
        }

      }
    },
    sendAuth() {
      if (this.username && this.password) {
        this.loginByUserName();
      } else if (this.account_id && (!this.username && !this.password)) {
        this.loginByPhone();
      }
    },
    async resend() {

      this.progressShow = true
      const accountId = localStorage.getItem("accountId");
      let MyJSON = JSON.stringify({ account_id: accountId });
      let api = process.env.VUE_APP_BASE_URL + "/auth/login"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
        this.progressShow = false;
        if (response.status >= 400) {
          this.showSnackBar("שגיאה בשליחת סמס", "red");
        } else if (response.status === 200) {
          this.showSnackBar("סמס נשלח בהצלחה!", "green");
        }
      } catch (error) {
        this.showSnackBar("Error Login: " + error, "red");
      }

    },
    handleEnterKeyOtp(event) {
      if (event.keyCode === 13) {
        this.smsVerify(); // Trigger the button click event
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async startListeningToOtp() {
      if ('OTPCredential' in window) {
        try {
          const content = await navigator.credentials.get({
            otp: { transport: ['sms'] },
            signal: this.abortController.signal, // Optional: for aborting the request
          });
          if (content) {
            this.smsCode = content.code;
            // Optionally, auto-submit the form after receiving the OTP
            setTimeout(() => {
              this.smsVerify();
            }, 700);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    beforeDestroy() {
      if (this.abortController) {
        this.abortController.abort();
      }
    }
  },
  watch: {
    $route() {
      document.title = 'Login | CLICK-CALL'
    },
  },
  mounted() {
    this.startListeningToOtp();
  },
  created() {
    this.abortController = new AbortController(); // Initialize abort controller
  }

}
</script>
<style>
.custom-text-field .v-input__control .v-input__slot {
  /* Background color for the text field */
  background-color: white;

  /* Rounded corners */
  border-radius: 20px;

  /* Border color and width */
  border: 2px solid #0052cc;
  /* Assuming a blue like in the picture */

  /* Internal padding */
  padding: 8px 16px;
}

.gradiant-bt {
  background: linear-gradient(94deg, rgba(140, 159, 245, 1) 1%, rgba(9, 104, 224, 1) 70%);
}
</style>
