<template>
  <div class="backgr" v-if="!isMobile">
    <img src="../assets/images/logo.svg" style="width: 15%; position: absolute; left: 7%; top: 7%;">
    <FormLogin v-if="!isMobile" />
  </div>
  <div class="backgr-mobile" v-else>
    <img src="../assets/images/logo.svg" style="width: 30%; position: absolute; left: 7%; top: 7%;">
    <img v-if="robotShow" src="../assets/images/robot_mobile.svg"
      style="position: absolute; right: 0%; top: 2%; bottom: 60%; max-height: 50%;">
    <FormLoginMobile @removeRobot="robotShow = false" />
  </div>
</template>
<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import FormLoginMobile from '@/components/auth/FormLoginMobile.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    FormLogin,
    FormLoginMobile
  },
  data: () => ({
    isMobile: false,
    email: "",
    password: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    robotShow: true,
  }),
  mounted() {
    document.title = 'כניסה | clickcallmobile'
    let auth = Auth.checkAuth();
    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfYWNjb3VudElkIjoiMDUzMjc4OTIyMSIsIl9leHBpcmVzSW4iOiIyLzEvMjAyNCwgODozOToxN-KAr1BNIiwiaWF0IjoxNzA2NzkxMTU3LCJleHAiOjE3MDY4MDE5NTd9.UeWjzmwdM63MGzeIzp7vj6f-ws5Xjwc6znq2VvBgVNk"
    // localStorage.setItem("token", token)
    if (auth) {
      this.$router.replace({ name: "callHistory" })
    }
    if (window.innerWidth <= 1000) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  beforeRouteLeave(to, from, next) {
     this.robotShow = true;
    next();
  },
}
</script>
<style scoped>
.backgr {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/images/login_back.svg');
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  transform: translateZ(0);
  animation: pages_colorIn__r4Ogf 2.5s ease-out;
}

.backgr-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/images/login_back_mobile.svg');
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  transform: translateZ(0);
  animation: pages_colorIn__r4Ogf 2.5s ease-out;
}

@keyframes pages_colorIn__r4Ogf {
  0% {
    filter: grayscale(1);
  }

  100% {
    filter: grayscale(0);
  }
}
</style>