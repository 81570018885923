import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/PageNotFoundView.vue'
import LoginView from '../views/LoginView.vue'
import SettingsView from '../views/SettingsView.vue'
import AdminView from '../views/admin/AdminView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/call-history',
    name: 'callHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/CallsView.vue'),
  },

  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
  },
  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
