<template>
  <div class="g">
    <div style="padding: 15px">
      <!-- to set the side yellow color absolute -->
      <div style="background-color: #ffb91a; width: 5%; position: absolute; top: 0; bottom: 0; right: 0;"></div>
      <div style="width: 100%; display: flex; justify-content: center;">
        <div
          style="display: flex; justify-content: space-between; margin-top: 2%; margin-bottom: 2%;  width: 85%; max-width:85%; ">
          <div>
            <v-btn outlined class="mx-2" fab dark medium style="background: linear-gradient(to right, #869afa, #2959df);"
              @click="logOut()">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="white">
                    mdi-logout
                  </v-icon>
                </template>
                <span>התנתק</span>
              </v-tooltip>
            </v-btn>
            <v-btn outlined class="mx-2" fab dark medium style="margin-left: 10px;background: linear-gradient(to right, #869afa, #2959df);"
              @click="moveToCalls()">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="white">
                    mdi-phone
                  </v-icon>
                </template>
                <span>מעבר לשיחות</span>
              </v-tooltip>
            </v-btn>
          </div>
          <img src="../../assets/images/logo.svg" style="width: 10%;">
        </div>
      </div>
      <UsersComp />
    </div>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>
  
<script>
import Auth from '@/services/auth.service.js';
import SnackBar from '@/components/widgets/snackBar.vue'
import UsersComp from '@/components/admin/users/UsersComp.vue'

export default {
  name: 'AdminPage',
  components: {
    UsersComp,
    SnackBar
  },
  data() {
    return {
    };
  },
  methods: {
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    logOut() {
      localStorage.removeItem("token")
      this.$router.replace({ name: 'login' });
    },
    moveToCalls() {
      this.$router.replace({ name: 'callHistory' });
    },
  },
  mounted() {
    document.title = 'ניהול | clickcallmobile'
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: 'login' });
    }
  },
};
</script>
  
<style scoped>
.g {
  height: 100%;
  background-image: url('../../assets/calls/calls_back.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 15px;
}
</style>
  