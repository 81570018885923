<template>
  <div class="g">
    <div v-if="!isMobile">
      <div style="width: 100%; display: flex; justify-content: center;">
        <div
          style="display: flex; justify-content: space-between; margin-top: 2%; margin-bottom: 2%;  width: 85%; max-width:85%; ">
          <img src="../assets/settings/icon_close.svg" style="width: 3%; cursor: pointer;" @click="goBack()">
          <img src="../assets/images/logo.svg" style="width: 10%;">
        </div>
      </div>
      <settingsComp />
    </div>

    <div v-else>
      <div style="display: flex; justify-content: end; margin-top: 4%; margin-left: 4%; margin-bottom: 3%;">
        <img src="../assets/images/logo.svg" style="width: 30%;">
      </div>
      <div style="width: 100%; display: flex; justify-content: center;">
        <div
          style="display: flex; justify-content: space-between; margin-top: 2%; margin-bottom: 2%;  width: 95%; max-width:100%;">
          <div>
            <h1 style="color: white;">הגדרות</h1>
            <h3 style="color: white;">תנאי השימוש</h3>
          </div>
          <img src="../assets/settings/icon_close.svg" @click="goBack()" style="width: 50px; cursor: pointer;">
        </div>
      </div>
      <settingsMobileComp />
    </div>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>
  
<script>
import Auth from '@/services/auth.service.js';
import settingsComp from '@/components/settings/settingsComp'
import settingsMobileComp from '@/components/settings/settingsMobileComp'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  name: 'CallsPage',
  components: {
    settingsComp,
    settingsMobileComp,
    SnackBar
  },
  data() {
    return {
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      isMobile: false,
    };
  },
  computed: {

  },
  methods: {

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    goBack() {
      this.$router.replace({ name: 'callHistory' });
    }

  },
  mounted() {
    document.title = 'הגדרות | clickcallmobile'
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: 'login' });
    }
    if (window.innerWidth <= 900) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
};
</script>
  
<style scoped>
.g {
  height: 100%;
  background-image: url('../assets/calls/calls_back.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px;
}
</style>
  