<template >
    <div style="margin-top: 30px;">
      <div style="display: flex; justify-content: center; width: 100%; ">
  
        <v-row style="background-color: white; max-width:99%; border-radius: 20px; padding: 50px 15px 15px 15px;">
  
          <v-col>
            <v-row
              style="box-shadow: 0 6px 20px rgba(119, 119, 119, 0.25); border-radius: 15px; padding: 5px; cursor: pointer;"  @click="openPrivacyPolicy()">
              <v-col cols="9">
                <div>
                  <span style="color: #0d2c6d; font-weight: bold; font-size: large;">תנאי שימוש</span>
                </div>
                <div>
                  <span>תנאי שימוש של האתר</span>
                </div>
              </v-col>
              <v-col cols="3">
                <div style="direction: ltr; display: flex; height: 100%; align-items: center;">
                  <img src="../../assets/settings/icon_center.svg" style="width: 20px; cursor: pointer;" @click="">
                </div>
              </v-col>
            </v-row>
  
              <v-row
                style="box-shadow: 0 6px 20px rgba(119, 119, 119, 0.25); border-radius: 15px; padding: 5px; margin-top: 40px; cursor: pointer;"
                @click="openPrivacyPolicy()">
                <v-col cols="9">
                  <div>
                    <span style="color: #0d2c6d; font-weight: bold; font-size: large;">מדיניות פרטיות</span>
                  </div>
                  <div>
                    <span style="color: black;">מדיניות פרטיות של האתר</span>
                  </div>
                </v-col>
                <v-col cols="3">
                  <div style="direction: ltr; display: flex; height: 100%; align-items: center;">
                    <img src="../../assets/settings/icon_center.svg" style="width: 20px; cursor: pointer;" @click="">
                  </div>
                </v-col>
              </v-row>
            
            <v-row @click="logOut()"
              style="box-shadow: 0 6px 20px rgba(119, 119, 119, 0.25); border-radius: 15px; padding: 5px; margin-top: 40px; margin-bottom: 100px; cursor: pointer;">
              <v-col cols="8">
                <div>
                  <span style="color: #1976d2; font-weight: bold; font-size: x-large;">התנתק</span>
                </div>
              </v-col>
              <v-col cols="4">
                <div style="direction: ltr; display: flex; height: 100%; align-items: center;">
                  <v-icon  @click="logOut()" large color="primary" style="cursor: pointer;">
                    mdi-logout-variant
                  </v-icon>
                </div>
              </v-col>
            </v-row>
  
          </v-col>
        </v-row>
      </div>
    </div>
  </template>
      
  <script>
  
  import SnackBar from '@/components/widgets/snackBar.vue'
  import Chart from 'chart.js/auto';
  
  export default {
    components: {
      SnackBar,
    },
    data: () => ({
    }),
  
    methods: {
      logOut() {
        localStorage.removeItem("token")
        this.$router.replace({ name: 'login' });
      },
      openPrivacyPolicy() {
        // Create a link for downloading
        const openLink = document.createElement("a");
        openLink.href = "https://app.telclick.co.il/privacy_policy.pdf"; // URL to the audio file
        openLink.target = "_blank"
  
        // Append link to the body, click it, and then remove it
        document.body.appendChild(openLink);
        openLink.click();
        document.body.removeChild(openLink);
      }
    },
    mounted() {
    },
  }
  </script>
  <style scoped></style>
      