<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" label="שם"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.account_id" type="number" hide-spin-buttons label="טלפון"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.username" :rules="[v => !!v || 'שדה חובה']" required
                                    label="שם משתמש"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle === 'הוסף משתמש'">
                                <v-text-field v-model="passObj.password" label="סיסמה" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle === 'ערוך משתמש'">
                                <v-text-field v-model="passObj.password" label="סיסמה"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="roles" :item-text="'hebrewName'" :item-value="'name'" hide-details
                                    v-model="itemEdited.role" outlined dense label="הרשאות">
                                </v-select>
                            </v-col>

                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text
                                @click="formTitle === 'ערוך משתמש' ? saveEditedUser() : saveNewUser()">
                                שמור
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            name: "",
            account_id: "",
            username: "",
            role: "user",
        },
        passObj: {
            password: "",
        },
        roles: [{ hebrewName: "משתמש", name: "user" }, { hebrewName: "מנהל", name: "admin" }],
    }),

    methods: {
        async saveNewUser() {

            if (this.$refs.form.validate()) {

                if (this.passObj.password.length < 9) {
                    return this.showSnackBar("הסיסמה חייבת לכלול לפחות 9 תווים", "red");
                }

                if (this.itemEdited.username.length < 4) {
                    return this.showSnackBar("username חייב לכלול לפחות 4 תווים", "red");
                }

                const userJSON = JSON.stringify({ user: this.itemEdited, passObj: this.passObj });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/create_user";
                    const res = await fetch(api, ApiServices.requestOptions("POST", userJSON, token));
                    const jsonObject = await res.json();
                    this.progressShow = false;
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בהוספת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id
                        this.$emit("userAdded", this.itemEdited, "המשתמש נוצר בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved user: " + error, "red");
                }
            }

        },

       
        async saveEditedUser() {

            if (this.$refs.form.validate()) {

                if (this.itemEdited.username.length < 4) {
                    return this.showSnackBar("username חייב לכלול לפחות 4 תווים", "red");
                }

                if (this.passObj.password) {
                    if (this.passObj.password.length < 9) {
                        return this.showSnackBar("חייבת לכלול לפחות 9 תווים", "red");
                    }
                }

                const userJSON = JSON.stringify({ user: this.itemEdited, passObj: this.passObj });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    const api = process.env.VUE_APP_BASE_URL + "/admin/edit_user";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", userJSON, token));
                    const jsonObject = await res.json();
                    this.progressShow = false;
                    if (res.status >= 400) {
                        this.showSnackBar("שגיאה בעריכת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("userEdited", this.itemEdited, "המשתמש עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit user: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך משתמש") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            this.itemEdited.role = this.itemEdited.role === "מנהל" ? "admin" : "user";
        }
    },
}
</script>
<style></style>
  